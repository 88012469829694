
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91slug_93cFqF3A5MTGMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as indexJe5I41s3bgMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as guidesxuvfojcE0nMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as _91response_93QfQaVx91b8Meta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as indexjIzeYpmOx1Meta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as schedule_45callbacknOTRWnoPaJMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as about_45usr4fdnVfnAGMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as callback1VFbhtyWhaMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as mergeVTCS5a03l0Meta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as buyer_45protection_45claimtkd70Y0fsFMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as terms_45and_45conditions7ffTrzSo8oMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as comprehensiveojsL8OnyfCMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as indexVoIIoKRQRqMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91step_93nWZzfcxFTLMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as detailsDKJ3RgNDTsMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsXf7Mne2SIXMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexmdy6XiF6SZMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexM9XB8gm9AkMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as collection_45noticeV3QY0fXmQZMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as complaintsFqbzvpt0FLMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usjPTDVcGrDcMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as _91slug_93O1SxWLecRNMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as indexORhufhA5FKMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as _91slug_932rEQwoGFczMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as _91slug_93Y2z5VZzwbfMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as account_45settings3c4KdCYMxYMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexy7OonQIk0IMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as claim_45offerqHHrf6BDymMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as indexYLBhpyPvOHMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as indexdRSdNmi5LpMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as index94a5q2VSaHMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as indexnPCpGxQGpLMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as additional_45detailsho6IBHy4DeMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as completionqWgQ6xw6CQMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as detailsc3l7KmTUb3Meta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as _91step_93mHrxBuGriyMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93Mu8XOhUcR1Meta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93hV0BF6QzJ7Meta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as _91step_93S5xSV6cCCSMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as _91step_93KnOFYCBnzYMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as lic_45detailsqC9cI6kQruMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as rebateaNDokkZMGcMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as digital_45eicQ2pgKChkhiMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as errorgtWXfS7iEbMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as thank_45you8VUwAWVGpJMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as coffs_45harbourvHVGy7huAIMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as indexKNJ9gYmYfDMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91step_93fWIxbeVKXTMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailsE6Al52jkwkMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as thank_45youhxGKUBoGMVMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as _91slug_93AJmhGyOD9rMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as indexQpkBdMRatwMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as _91author_93QFfaU04KzOMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as indexJY1IVSe2qMMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as forgot_45password8X6SYJcjZSMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as set_45passwordf24A0W7jEQMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45back4vMiaS3S1lMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as _91step_93k89Gk1epbhMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as expiredxt0VdU9UxpMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexqFnLK7ZBD1Meta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as indexpM4VQZ9j8gMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as error6TNc7cEi1DMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexde9gMxyHE9Meta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as live_45chat_45b2g71AuoJoYMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as live_45chattzbLSkB9qqMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as indexNccgma8AlrMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addK5MrWREZxnMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as signupkXJqU8qZhcMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as post_45salem1FvMLBJIRMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as indexKHN4WltKREMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as _91step_93GRefyMTEwyMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as details6ZfZWZ0qAaMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0e91g58v4ccMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as details2jqxNqV5ggMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as _91step_93RC6suT7kJPMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as step00m1s1dW1OKMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as indexmtAZ2ZF78sMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as thank_45you5vqRqlgXhuMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as indexlX0OPnqHIwMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as indexKFgul0H0G3Meta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as indexg9nu3TMtMfMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as indexpGMUBnhvqcMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as _91step_93yPq9PJdTMGMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as _91step_93cDrGXJ5c5uMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as confirmationdlTHksUPGTMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as detailsWLiRvsZZxCMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as indexVEXXWdCm2hMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as quotes_45resultsujeaoUNmdwMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as thank_45youWTi8C8LsyWMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as _91type_937wvS8mK2FbMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as index9eQFHJj0hjMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as indexkJZ8VcY8amMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as interest_45ratesXvP0dwWdtzMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91step_93pQ58Ar81foMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as detailsFqrQ8t9kLbMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as thank_45youVSqu2OYcKdMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as indexZqqT07OCgpMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/index.vue?macro=true";
import { default as existing_45customersI4o9pEsxFsMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as confirmationm7c8tIeHgnMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as detailstMepNlcu6rMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as indexwNdMQFo4ZPMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as indexOzIZV4a4kRMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as add0alxrASS2zMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as thank_45youCy77KS81nAMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as index4Lw21rGODZMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as success2mriWyIteiMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as _91step_93tKz5Q6yy9yMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue?macro=true";
import { default as detailslUGXS4rWw6Meta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as step0U4apvC48slMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue?macro=true";
import { default as loginTG1sFsgJe1Meta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/login.vue?macro=true";
import { default as _91slug_93nwdCN7FAZBMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as _91author_93qqc4RBGPEKMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as indexg0N8Q013DXMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as partnershipsPQZmQaXsGUMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as index4MvSXk79o3Meta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91step_93s0tbrgbXrrMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as detailsd3FMthYq1PMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as thank_45youGpwwwK9i0uMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as post_45sale_45thank_45youn4PTpX2EeCMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as _91slug_933kVsgmF2NQMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93wlI6fuIMKgMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as _91slug_93tkGkQnmHp2Meta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as privacy_45policydspqqxKq7RMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as registerzpLMq5HH1MMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/register.vue?macro=true";
import { default as reset_45passwordM4np0Vb1y9Meta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as sitemap5iPfvw3WXoMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as terms_45of_45useoRmfrD5tEOMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as verifygIk4T9PedeMeta } from "/codebuild/output/src4156277460/src/packages/nuxt/pages/verify.vue?macro=true";
export default [
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: schedule_45callbacknOTRWnoPaJMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  },
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  }
]
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93O1SxWLecRNMeta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settings3c4KdCYMxYMeta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexy7OonQIk0IMeta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offerqHHrf6BDymMeta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexYLBhpyPvOHMeta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexdRSdNmi5LpMeta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: index94a5q2VSaHMeta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: indexnPCpGxQGpLMeta?.props ?? {"verificationNeeded":true},
    meta: indexnPCpGxQGpLMeta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product(.*)*/:vertical()/additional-details",
    meta: additional_45detailsho6IBHy4DeMeta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product(.*)*/:vertical()/completion",
    meta: completionqWgQ6xw6CQMeta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product(.*)*/details",
    meta: detailsc3l7KmTUb3Meta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product(.*)*/form/energy/step/:step()",
    meta: _91step_93mHrxBuGriyMeta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product(.*)*/form/generic/step/:step()",
    meta: _91step_93Mu8XOhUcR1Meta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product(.*)*/form/health-insurance/step/:step()",
    meta: _91step_93hV0BF6QzJ7Meta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product(.*)*/form/home-loan/step/:step()",
    meta: _91step_93S5xSV6cCCSMeta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product(.*)*/form/life-insurance/step/:step()",
    meta: _91step_93KnOFYCBnzYMeta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product(.*)*/lic-details",
    meta: lic_45detailsqC9cI6kQruMeta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product(.*)*/rebate",
    meta: rebateaNDokkZMGcMeta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password8X6SYJcjZSMeta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45passwordf24A0W7jEQMeta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93RC6suT7kJPMeta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "life-insurance-quote-form-step",
    path: "/life-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/quote/form/[step].vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "life-insurance-quote-form-step0",
    path: "/life-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/life-insurance/quote/form/step0.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginTG1sFsgJe1Meta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerzpLMq5HH1MMeta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordM4np0Vb1y9Meta || {},
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src4156277460/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]